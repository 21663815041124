<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix">
      <el-button
        style="float: right"
        type="primary"
        @click="dialogFormVisible = true"
        >添加用户</el-button
      >
    </div>
    <el-dialog :title="form._id ? '更新' : '添加'" :visible.sync="dialogFormVisible">
      <el-form :model="form">
        <el-form-item label="用户头像" :label-width="formLabelWidth">
          <el-upload
            ref='uploadImgs'
            class="avatar-uploader"
            action="#"
            :show-file-list="false"
            :http-request="uploadFile"
            :before-upload="beforeAvatarUpload"
            :on-success="clearimgfile"
          >
            <img v-if="form.avatar" :src="form.avatar" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="登录账户" :label-width="formLabelWidth">
          <el-input v-model="form.username" autocomplete="off" placeholder="请输入用户登录的账户"></el-input>
        </el-form-item>
        <el-form-item v-if="!form._id" label="登录密码" :label-width="formLabelWidth">
          <el-input v-model="form.password" autocomplete="off" placeholder="请输入登录密码"></el-input>
        </el-form-item>
        <el-form-item label="姓名" :label-width="formLabelWidth">
          <el-input v-model="form.name" autocomplete="off" placeholder="请输入用户的真实姓名"></el-input>
        </el-form-item>
        <el-form-item label="手机" :label-width="formLabelWidth">
          <el-input v-model="form.phone" autocomplete="off" placeholder="请输入用户的真实姓名"></el-input>
        </el-form-item>
        <el-form-item label="职位" :label-width="formLabelWidth">
          <el-input v-model="form.position" autocomplete="off" placeholder="请输入用户的真实姓名"></el-input>
        </el-form-item>
        <el-form-item label="介绍" :label-width="formLabelWidth">
          <el-input v-model="form.dec" autocomplete="off" placeholder="请输入用户详细的介绍"></el-input>
        </el-form-item>
        <el-form-item label="角色" :label-width="formLabelWidth">
          <el-select ref="selectroleid" v-model="form.roleid" placeholder="请选择该用户的的权限" @change="selectroleid($event)">
            <el-option 
              v-for="item in options"
              :key="item._id"
              :label="item.name"
              :value="item.rolename"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="患者信息脱敏" :label-width="formLabelWidth">
          <el-radio-group v-model="form.checkuserphone">
              <el-radio :label="true">是</el-radio>
              <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="是否在职" :label-width="formLabelWidth">
          <el-radio-group v-model="form.isreign">
              <el-radio :label="true">是</el-radio>
              <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelledit">取 消</el-button>
        <el-button v-if="!form._id" type="primary" @click="adduser(form)">添 加</el-button>
        <el-button v-if="form._id" type="primary" @click="edituser(form)">更 新</el-button>
      </div>
    </el-dialog>
    <!--修改-->
    <el-dialog title="修改用户" :visible.sync="editdialogFormVisible">
      <el-form :model="editform">
        <el-form-item label="登录名" :label-width="formLabelWidth">
          <el-input v-model="editform.username" autocomplete="off" placeholder="请输入登录名"></el-input>
        </el-form-item>
        <el-table-column label="封面">
        <template slot-scope="scope">
            <el-popover placement="top-start" title="" trigger="hover">
              <img :src="scope.row.imageUrl" alt="" style="width: 150px;height: 150px">
              <img slot="reference" :src="scope.row.imageUrl" style="width: 30px;height: 30px">
            </el-popover>
        </template>
        </el-table-column>
        <el-form-item label="姓名" :label-width="formLabelWidth">
          <el-input v-model="editform.name" autocomplete="off" placeholder="请输入用户姓名"></el-input>
        </el-form-item>
        <el-form-item label="手机" :label-width="formLabelWidth">
          <el-input v-model="editform.phone" autocomplete="off" placeholder="请输入手机"></el-input>
        </el-form-item>
        <el-form-item label="职位" :label-width="formLabelWidth">
          <el-input v-model="editform.position" autocomplete="off" placeholder="请输入职位"></el-input>
        </el-form-item>
        <el-form-item label="介绍" :label-width="formLabelWidth">
          <el-input v-model="editform.dec" autocomplete="off" placeholder="请输入职位"></el-input>
        </el-form-item>
        <el-form-item label="角色" :label-width="formLabelWidth">
          <el-select v-model="editform.roleid" placeholder="请选择该用户的的权限">
            <el-option 
              v-for="item in options"
              :key="item._id"
              :label="item.name"
              :value="item.rolename"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否在职" :label-width="formLabelWidth">
          <el-radio-group v-model="editform.isreign">
              <el-radio :label="true">是</el-radio>
              <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelledit">取 消</el-button>
        <el-button type="primary" @click="edituser(editform)"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <!--修改-->
    <el-table
      :data="items"
      style="width: 100%; margin-bottom: 20px"
      row-key="_id"
      border
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column prop="username" label="登录账户" width="120" > </el-table-column>
      <el-table-column label="姓名">
        <template slot-scope="scope">
          <el-popover placement="top-start" title="" trigger="hover">
            <img
              :src="scope.row.avatar"
              alt=""
              style="width: 150px; height: 150px"
            />
            <img
              slot="reference"
              :src="scope.row.avatar"
              style="width: 50px; height: 50px"
            />
          </el-popover>
          {{ scope.row.name }}
        </template>
      </el-table-column>
      <el-table-column prop="phone" label="手机" width="180"></el-table-column>
      <el-table-column prop="position" label="职位" width="100"> </el-table-column>
      <el-table-column prop="dec" label="介绍" width="180"></el-table-column>
      <el-table-column
          prop="tag"
          label="在职"
          width="120"
          >
          <template slot-scope="scope">
          <el-tag
              disable-transitions>{{scope.row.isreign}}</el-tag>
          </template>
      </el-table-column>
      <el-table-column
          prop="tag"
          label="角色ID"
          width="120"
          >
          <template slot-scope="scope">
          <el-tag
              disable-transitions>{{scope.row.roleid}}</el-tag>
          </template>
      </el-table-column>
      <el-table-column prop="date" label="创建时间" width="180"> </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleedit(scope.row)"
            >编辑</el-button
          >
          <el-button
            size="mini"
            type="danger"
            @click="handleDelete(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      @current-change="handlePageChange"
      @size-change="handlePageSizeChange"
      :current-page="currentPage"
      :page-sizes="[5, 10, 20, 40]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
  </el-card>
</template>
<script>

export default {
  data() {
    return {
      editdialogFormVisible: false,
      dialogFormVisible: false,
      formLabelWidth: "120px",
      currentPage: 1, // 当前页码
      pageSize: 5, // 每页数据量
      total: 0, // 总数据量
      items: [],
      form: {
        username: "",
        password:"",
        name: "",
        phone: "",
        roleid: "",
        role_id: "",
        position:"",
        isreign:"",
        dec:"",
        avatar:"",
      },
      editform: {
        _id:"",
        username: "",
        name: "",
        phone: "",
        roleid: "",
        role_id: "",
        position:"",
        isreign:"",
        dec:"",
        avatar:"",
      },
      options: [],
      checkoptions: [],
      tableData: [],
    };
  },
  methods: {
    selectroleid(event){
      console.log(event)
      let obj = {};
      obj = this.options.find((item)=>{
        return item.rolename === event;
      });
      this.form.role_id = obj._id
      this.editform.role_id = obj._id
      console.log(obj._id)
    },
    clearimgfile(){
      this.$refs.uploadImgs.clearFiles();
    },
    uploadFile(param) {
      const formData = new FormData();
      formData.append("avatar", param.file);
      console.log(param.file);
      this.$api.upload
        .doctoravatar(formData)
        .then((res) => {
          this.form.avatar = res.data.data;
        })
        .catch((res) => {
          this.$message.error("上传失败，请稍后重试");
        });
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    cancelledit(){
      this.dialogFormVisible = false;
      this.form = {}
      this.editform = {}
    },
    edituser(data){
      this.$api.sysusers.edituser(data).then(res =>{
        this.$message({
          message:res.data.msg,
          type: 'success'
        });
        this.dialogFormVisible = false;
        this.loadItems();
        this.form = {}
      })
    },
    handleedit(data){
      this.dialogFormVisible = true;
      this.form = data
    },
    handleDelete(data){
      this.$api.sysusers.deleteuser(data).then(res =>{
        this.$message({
          message:res.data.msg,
          type: 'success'
        });
        this.loadItems()
      })
    },
    adduser() {
      const data = {
        username: this.form.username,
        password: this.form.password,
        name: this.form.name,
        name: this.form.name,
        phone: this.form.phone,
        roleid: this.form.roleid,
        role_id: this.form.role_id,
        position: this.form.position,
        isreign: this.form.isreign,
        dec: this.form.dec,
        avatar: this.form.avatar,
        checkuserphone: this.form.checkuserphone,
      }
      //console.log(this.form.parent)
      this.$api.sysusers.createuser(data).then(res =>{
        this.$message({
          message:res.data.msg,
          type: 'success'
        });
        this.dialogFormVisible = false
        this.loadItems();
        this.form = {}
      })
    },
    handlePageChange(page) {
      this.currentPage = page;
      this.loadItems();
    },
    handlePageSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.loadItems();
    },
    loadItems(){
      const data = {
        currentPage: this.currentPage,
        pageSize:this.pageSize
      }
      this.$api.sysusers.userlist(data).then(res =>{
        this.currentPage = res.data.pagination.currentPage;
        this.pageSize = res.data.pagination.pageSize;
        this.total = res.data.pagination.total;
        this.items = res.data.data;
      })
    },
    getroleid(){
        this.$api.roleids.getroleidlist().then(res =>{
            this.options = res.data.data
        })
    }
  },
  created(){
    this.loadItems()
    this.getroleid()
  }
};
</script>
<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}

.box-card {
  width: 100%;
}
</style>  